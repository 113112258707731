
.border-booker .border-subtle.flex { 
  min-width: 340px;
}

body { 
  background: url(https://software-design.de/static/img/background.jpg) !important;
}


:is(.dark .dark\:bg-default){
  background-color: transparent !important;
}

.bg-subtle {
  background-color: transparent !important;
}

.mb-8.text-center {
 background-color: var(--cal-bg) !important;
 padding: 10px;
}
